export const WORDS = [
  'booby',
  'tooty',
  'pokii',
  'sopee',
  'bubba',
  'poopy',
  'pepee',
  'babey',
  'dodoe',
  'tieti',
  'booba',
  'bopee',
  'bopii',
  'booty',
  'twink',
  'pussy',
  'gordo',
  'rocks',
  'soupe',
  'tummy',
  'cutie',
  'danny',
  'honey'
]
